import Form from '@/services/Form'
import Errors from '@/services/Errors'

import OpenEye from '../../../components/icons/OpenEye.vue'
import ClosedEye from '../../../components/icons/ClosedEye'

export const OTP = {
  components: { ClosedEye, OpenEye },
  data() {
    return {
      errors: [],
      confirmForm: new Form({
        verificationCodeNumber1: '',
        verificationCodeNumber2: '',
        verificationCodeNumber3: '',
        verificationCodeNumber4: '',
        verificationCodeNumber5: '',
        verificationCodeNumber6: ''
      }),

      countDown: 60,
      smsLimit: 0,
      error: false,
      errors: new Errors(),

      form: new Form({
        password: '',
        confirmPassword: ''
      }),
      passFieldType: 'password',
      error: false
    }
  },

  computed: {
    userPhone() {
      return this.$store.getters['Globals/userPhone']
    }
  },
  mounted() {
    this.countDownTimer()
  },

  methods: {
    goNext2() {
      if (this.confirmForm.verificationCodeNumber1) {
        this.$refs.input2.focus()
      }
    },
    goNext3() {
      if (this.confirmForm.verificationCodeNumber2) {
        this.$refs.input3.focus()
      }
    },

    goNext4() {
      if (this.confirmForm.verificationCodeNumber3) {
        this.$refs.input4.focus()
      }
    },

    goNext5() {
      if (this.confirmForm.verificationCodeNumber4) {
        this.$refs.input5.focus()
      }
    },

    goNext6() {
      if (this.confirmForm.verificationCodeNumber5) {
        this.$refs.input6.focus()
      }
    },

    reSendCode() {
      if (this.smsLimit < 3) {
        this.$store
          .dispatch('Globals/reSendCode', {
            phone: this.userPhone,
            app_version: ''
          })
          .then(() => {
            this.countDown = 60
            this.countDownTimer()
          })
      }

      this.smsLimit += 1


    },


    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },

    onSwitchPass() {
      this.passFieldType =
        this.passFieldType === 'password' ? 'text' : 'password'
    }
  }
}
